body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  background-color: #efefef;
  min-height: 100vh;
}

#root {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@media screen and (orientation: portrait) {
  html {
    transform: rotate(90deg);
  }
  body {
    min-height: 100vw;
  }
}

.App {
  user-select: none;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  background: url(../public/background.webp) no-repeat center center fixed;
  background-size: cover;
  justify-content: center;
}

@media screen and (orientation: portrait) {
  .App,
  .App .css-tagjnq {
    height: 100vw;
    width: 100vh;
  }
}

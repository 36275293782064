.pattern {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: border-radius 0.2s;
}

.pattern.close {
  aspect-ratio: 500 /726;
  display: inline-block;
  border-radius: 5px;
  border: 1px solid;
  /* box-shadow: 5px 5px 5px #999; */
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.pattern:hover .title {
  display: flex;
  align-items: center;
  justify-content: center;
}
.no-shadow {
  box-shadow: none;
}

.pattern .title {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: "Lato", sans-serif;
  background: rgba(0, 0, 0, 0.5);
  border-radius: inherit;
  color: white;
}
.pattern.one {
  background: linear-gradient(-45deg, white 50%, transparent 50%) 8px 0,
    linear-gradient(45deg, transparent 50%, pink 50%), white;
  background-size: 32px 32px;
}
.pattern.two {
  background: linear-gradient(-45deg, plum 25%, transparent 50%, plum 25%) 16px
      0,
    linear-gradient(45deg, plum 25%, transparent 50%, plum 25%), black;
  background-size: 32px 128px;
}
.pattern.three {
  background-color: white;
  background-image: radial-gradient(
    navy 4px,
    aqua 5px,
    transparent 12px,
    transparent 15px,
    darkblue 16px,
    darkblue 20px,
    transparent 21px,
    transparent 25px
  );
  background-size: 60px 60px;
}
.pattern.four {
  background: linear-gradient(-45deg, white 50%, transparent 50%) 1px 0,
    linear-gradient(45deg, transparent 50%, rosybrown 50%),
    linear-gradient(135deg, white 50%, transparent 50%) 16px 0,
    linear-gradient(-135deg, transparent 50%, rosybrown 50%), white;
  background-size: 18px 54px;
}
.pattern.five {
  background: linear-gradient(60deg, mintcream 50%, transparent 50%) 6px 0,
    linear-gradient(-60deg, transparent 50%, midnightblue 50%), mintcream;
  background-size: 32px 32px;
}
.pattern.six {
  background: radial-gradient(circle, white 20px, skyblue 10px), skyblue;
  background-size: 25px 60px;
}
.pattern.seven {
  background: radial-gradient(snow 16px, transparent 16px), chocolate;
  background-size: 30px 30px;
}
.pattern.eight {
  background: linear-gradient(90deg, burlywood 46%, transparent 50%),
    linear-gradient(180deg, transparent 50%, burlywood 54%), bisque;
  background-size: 20px 20px;
}
.pattern.nine {
  background: linear-gradient(0deg, white 15%, transparent 15%) 0 6px,
    linear-gradient(90deg, white 50%, transparent 50%) -10px 0,
    linear-gradient(120deg, black 50%, transparent 50%),
    linear-gradient(60deg, transparent 49%, black 50%), white;
  background-size: 40px 40px;
}
.pattern.ten {
  background: linear-gradient(-60deg, seashell 50%, transparent 50%) 8px 0,
    linear-gradient(60deg, transparent 50%, PaleVioletRed 50%), seashell;
  background-size: 16px 32px;
}
.pattern.eleven {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 20px, transparent 20px),
    linear-gradient(0deg, transparent 50%, rgba(200, 0, 0, 0.5) 50%) 20px 20px,
    linear-gradient(0deg, rgba(0, 0, 0, 0.5) 50%, transparent 50%) 40px 0,
    linear-gradient(180deg, transparent 20px, rgba(0, 0, 0, 0.5) 20px) 40px 0,
    floralwhite;
  background-size: 40px 40px;
}
.pattern.twelve {
  background: linear-gradient(75deg, rgba(0, 0, 0, 0.4) 8px, transparent 10px),
    linear-gradient(-75deg, rgba(0, 0, 0, 0.4) 8px, transparent 10px),
    linear-gradient(60deg, rgba(0, 0, 0, 0.6) 10px, transparent 10px),
    linear-gradient(-60deg, rgba(0, 0, 0, 0.6) 10px, transparent 10px),
    linear-gradient(45deg, black 8px, transparent 8px),
    linear-gradient(-45deg, black 8px, transparent 8px),
    linear-gradient(255deg, rgba(0, 0, 0, 0.4) 8px, transparent 10px),
    linear-gradient(-255deg, rgba(0, 0, 0, 0.4) 8px, transparent 10px),
    linear-gradient(240deg, rgba(0, 0, 0, 0.6) 10px, transparent 10px),
    linear-gradient(-240deg, rgba(0, 0, 0, 0.6) 10px, transparent 10px),
    linear-gradient(135deg, black 8px, transparent 8px),
    linear-gradient(-135deg, black 8px, transparent 8px), white;
  background-size: 90px 90px;
}
/* Table Cards */
.opponent {
  left: 22%;
}
.player {
  right: 25%;
}
.trump {
  left: 18%;
  border-radius: 4px;
  box-shadow: -2px 4px 0 -1px white, -2px 4px rgb(40, 37, 37),
    -4px 6px 0 -1px white, -4px 6px rgb(40, 37, 37), -6px 8px 0 -1px white,
    -6px 8px rgb(40, 37, 37), -8px 10px 0 -1px white, -8px 10px rgb(40, 37, 37) !important;
}
.text {
  color: white;
  font-size: calc(10px + 1vmin);
  font-family: "Inter", sans-serif;
  font-weight: 500;
  top: 25%;
  z-index: 11;
  left: 50%;
  /* transform: translate(-50%, -50%); */
  text-transform: capitalize;
  mix-blend-mode: exclusion;
  position: absolute;
}
.trumpText {
  left: 65%;
}
.playerText {
  left: 10%;
}
.opponentText {
  left: 80%;
}

.card {
  position: relative;
  height: 24vh;
  top: 45%;
  display: inline-block;
  aspect-ratio: 123/179;
  transform: translate(0%, -50%);
}

.score1 {
  transform: rotate(36deg) translate(26%, -6%);
}
.score2 {
  top: 35%;
}
.score3 {
  left: 52% !important;
}
.score4 {
  top: 65%;
}
.score5 {
  top: 65% !important;
  left: 52% !important;
}

@media only screen and (max-width: 920px) {
  .text {
    top: 23%;
    font-size: small;
    transform: translate(-16%, 0);
  }
}
@media screen and (orientation: portrait) {
  .card {
    height: 24vw;
  }
  /* .text {
    top: 27%;
  } */
}
/* STYLES - END */

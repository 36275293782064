.alert-border {
  border: 4px solid red;
  animation: blink 1s;
  animation-iteration-count: 5;
}

@keyframes blink {
  50% {
    border-color: azure;
  }
}
.auction {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: monospace;
  width: 20% !important;
  height: 25% !important;
  background-color: aqua;
  text-transform: capitalize;
}
.auction span:last-child {
  margin-left: 4% !important;
}
.auction span {
  padding: 0 !important;
  margin: 5px !important;
  border: 2px solid black;
  font-size: large;
  font-weight: 600;
  cursor: pointer;
  background-color: antiquewhite;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.auction .visited {
  opacity: 0.5;
  cursor: not-allowed;
}

.avatar-west {
  left: 0%;
  top: 50%;
  transform: translate(0, -50%);
}
.avatar-north {
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}
.avatar-east {
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
}
.avatar-south {
  bottom: 0;
  width: 8% !important;
}
.avatar-text {
  background: black;
  position: absolute;
  color: white;
  bottom: -6%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  text-transform: capitalize;
  padding: 3%;
  font-family: sans-serif;
  letter-spacing: 0.1em;
}
.avatar {
  width: 12%;
  aspect-ratio: 1;
  position: absolute;
  z-index: 1;
  background: black;
  border-radius: 50%;
  overflow: hidden;
}
.scoreboard {
  z-index: 5;
  position: absolute;
  left: 2%;
  padding: 10px 20px;
  top: 1%;
  border-radius: 20%;
}

.player-south {
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
  width: fit-content;
  height: 24vh;
}

.player-south li img {
  object-fit: contain !important;
  display: block;
  width: auto !important;
  cursor: pointer;
}

.player-north {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
  height: 12%;
  width: fit-content;
}
.player-west {
  position: absolute;
  left: 5%;
  top: 5% !important;
  height: 15%;
  width: fit-content;
  transform-origin: left;
  transform: rotate(90deg) translate(0px, -50%);
}
.player-east {
  position: absolute;
  right: 1%;
  top: 50%;
  height: 12%;
  width: fit-content;
  transform: rotate(-90deg) translate(0%, -10%);
}

.css-14240oh-MuiSnackbar-root {
  width: 100vmax;
  justify-content: center;
  line-height: 0 !important;
}

.rotate-circle {
  display: block;
  width: 92%;
  height: 92%;
  border-radius: 50%;
  padding: 2.5px;
  background-clip: content-box;
  border: 5px solid grey;
}
.active {
  animation: spin 10s linear infinite;
  background-color: #4bb543;
  border: 5px dashed #7cfc00;
}
.active-bidder {
  animation: spin 10s linear infinite;
  background-color: #4bb543;
  border: 5px dashed #f0d500;
}

@keyframes spin {
  100% {
    transform: rotateZ(360deg);
  }
}

@media only screen and (max-width: 920px) {
  .rotate-circle {
    padding: 0;
  }
  .scoreboard {
    padding: 5px 10px;
    top: 0;
    left: 0;
    border-radius: 0% 21% 0% 20%;
  }
  .scoreboard p {
    font-size: small;
  }
  .scoreboard h6 {
    font-size: 1rem;
    line-height: 1.3;
  }
  .avatar {
    width: 11%;
  }
  .avatar-text {
    letter-spacing: 0;
    bottom: -10%;
    font-size: 0.7em;
  }
  .avatar-south .avatar-text {
    bottom: -12%;
    font-size: 0.5rem;
  }
  .auction span {
    border: 1px solid black;
    margin: 4px !important;
  }
  .auction {
    top: 56%;
    left: 55%;
    width: 40% !important;
    height: 48% !important;
  }
}
@media screen and (orientation: portrait) {
  .player-south {
    height: 24vw !important;
  }
  .MuiSnackbar-root {
    width: 100vh;
    left: 0 !important;
  }
  .MuiModal-root,
  .MuiBackdrop-root {
    width: 100vh;
  }
  .MuiDrawer-paperAnchorRight {
    right: auto !important;
  }
}

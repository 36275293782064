.table {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 92vh;
  width: 135vh;
  box-shadow: 0px 0px 10px inset, 0px 49px 49px;
  border-radius: 50%;
}

.table-cards {
  height: 40vh;
  width: 40vh;
  z-index: 5;
  position: absolute;
  top: 50%;
  left: 42%;
  transform: translate(-50%, -50%);
}

.west {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(22%, -50%) rotate(90deg);
}

.east {
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translate(-22%, -50%) rotate(90deg);
}
.south {
  position: absolute;
  bottom: 0;
  transform: translate(-50%, 0%);
  left: 50%;
}
.north {
  position: absolute;
  top: 0;
  transform: translate(-50%, 0%);
  left: 50%;
}
@media screen and (orientation: portrait) {
  .table {
    height: 92vw;
    width: 135vw;
  }
  .table-cards {
    height: 40vw;
    width: 40vw;
  }
}
